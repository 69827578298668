// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
// See polyfill at bottom of page, object.assign was not a function
if (typeof Object.assign != 'function') {
  Object.assign = function (target) {
    'use strict';
    if (target == null) {
      throw new TypeError('Cannot convert undefined or null to object');
    }

    target = Object(target);
    for (var index = 1; index < arguments.length; index++) {
      var source = arguments[index];
      if (source != null) {
        for (var key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key];
          }
        }
      }
    }
    return target;
  };
}

import Glider from "glider-js"

window.addEventListener('turbolinks:load', function () {
  if (document.querySelector('.glider') !== null) {

    new Glider(document.querySelector('.glider'), {
      slidesToScroll: 1,
      slidesToShow: 1,
      itemWidth: 150,
      arrows: {
        prev: '.glider-prev',
        next: '.glider-next'
      }, responsive: [
        {
          // screens greater than >= 1024px
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            itemWidth: 100,
            duration: 0.25
          }
        }, {
          // screens greater than >= 1300px
          breakpoint: 1300,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            itemWidth: 200,
            duration: 0.25
          }
        }
      ]
    });
    setTimeout(function () {
      Glider(document.querySelector('.glider')).refresh()
    }, 100)
  }
})