import Clipboard from 'clipboard'

let $jq = require('jquery')

document.addEventListener("DOMContentLoaded", function (e) {
  $jq("#social-share-link").hide();

  new Clipboard('.js-socialsharecopybtn');
  var copySocialShareBtn = document.querySelector('.js-socialsharecopybtn');

  copySocialShareBtn.addEventListener('click', function (event) {
    var socialsharelink = document.querySelector('.js-socialsharelink');
    var range = document.createRange();
    range.selectNode(socialsharelink);
    window.getSelection().addRange(range);
  });
})