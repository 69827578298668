let d3 = require('d3')
let $jq = require('jquery')
let topojson = require('topojson')
d3.geo = require('d3-geo')
$jq.select2 = require('select2')
import './map.css'
import 'select2/dist/css/select2.css'

document.addEventListener("turbolinks:load", function(e) {

  let width = 450,
    height = 450,
    boilerPlate = $jq("#boiler_plate"),
    regionalReport = $jq("#regional_report"),
    regionSelect = $jq("#region_select"),
    yearSelect = $jq("#year_select"),
    chartDiv = document.getElementById("region_map"),
    activeKeys = JSON.parse(chartDiv.dataset.mapKeys),
    year = chartDiv.dataset.year;

  if (year) {
    yearSelect.val(year)
  }

  let projection = d3.geo.geoMercator()
      .translate([4350, 1625])
      .scale(2000);

  let path = d3.geo.geoPath()
      .projection(projection);

  let svg = d3.select(chartDiv).append("svg")
      .attr("width", width)
      .attr("height", height);

  regionSelect.select2({
    width: 250,
    placeholder: "Choose One or More Counties"
  });

  regionSelect.on("change", function (e) {
    activeKeys = [];
    let index = 0,
      array = regionSelect.select2('data');
    while (index < array.length) { 
      activeKeys.push(array[index].id); 
      index++;
    }
    updateForms();
    updateElements();

    $(".select2-search__field").attr("aria-label", "Select one or more counties");
  });

  yearSelect.on("change", function (e) {
    updateForms();
    if(activeKeys.length > 0) {
      fetchNewReport();
    }
  });

  svg.append("rect")
      .attr("class", "background")
      .attr("width", width)
      .attr("height", height)
      .on("click", clicked);

  let g = svg.append("g")
    .style("stroke-width", "1.5px");

  d3.json("/geojson/california-counties.json")
    .then(function(data) {
      g.selectAll("path")
          .data(topojson.feature(data, data.objects.cb_2015_california_county_20m).features)
        .enter().append("path")
          .attr("d", path)
          .attr("id", function(d) { return "path"+d.properties.KEY })
          .attr("class", "feature")
          .on("click", clicked);

      g.append("path")
          .datum(topojson.mesh(data, data.objects.cb_2015_california_county_20m, function(a, b) { return a !== b; }))
          .attr("id", "state-borders")
          .attr("d", path);

      let sorted_data = topojson
        .feature(data, data.objects.cb_2015_california_county_20m)
        .features.sort(function(a, b) {
          let nameA = a.properties.NAME.toUpperCase();
          let nameB = b.properties.NAME.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

      d3.select("#region_select")
        .selectAll("option")
        .data(sorted_data)
        .join("option")
          .text(function(d) { return d.properties.NAME })
          .attr("value", function(d) { return d.properties.KEY });

      regionSelect.val(activeKeys)
      regionSelect.trigger('change');
    });

  function updateForms() {
    updateDownloadForm();
    updateSocialShareForm();
  }

  function updateDownloadForm() {
    document.getElementById('report_request_form_map_keys').value = activeKeys;
    document.getElementById('report_request_form_year').value = yearSelect.val();
  }

  function updateSocialShareForm() {
    document.getElementById('social_share_map_keys').value = activeKeys;
    document.getElementById('social_share_year').value = yearSelect.val();
  }

  function updateElements() {
    if (activeKeys.length > 0) {
      goGoGo();
    } else {
      reset();
    }
  }

  function goGoGo() {
    // We don't have to worry about updating the select.
    // All of that is taken care of and is what triggers this
    //
    // UnHighlight the correct shapes on the map
    d3.selectAll("path").classed("active", false);
    let index = 0;
    let xMin = 100000,
      xMax = 0,
      yMin = 100000,
      yMax = 0,
      p = d3.select(null);
    while (index < activeKeys.length) { 
      p = d3.select("path#path"+activeKeys[index]);
      // Highlight the correct shapes on the map
      p.classed("active", true);
      // Update bounds
      let bounds = path.bounds(p.data()[0]);
      xMin = Math.min(xMin, bounds[0][0]);
      xMax = Math.max(xMax, bounds[1][0]);
      yMin = Math.min(yMin, bounds[0][1]);
      yMax = Math.max(yMax, bounds[1][1]);
      index++;
    }
    // Zoom to bounds
    let current_width = chartDiv.clientWidth;
    let current_height = chartDiv.clientHeight;
    let dx = xMax - xMin,
        dy = yMax - yMin,
        x = (xMin + xMax) / 2,
        y = (yMin + yMax) / 2,
        scale = 0.3 / Math.max(dx / current_width, dy / current_height),
        translate = [current_width / 2 - scale * x, current_height / 2 - scale * y];

    g.transition()
        .duration(750)
        .style("stroke-width", 1.0 / scale + "px")
        .attr("transform", "translate(" + translate + ")scale(" + scale + ")");

    fetchNewReport()
  }

  function fetchNewReport() {
    $jq.ajax({
      url: "/region_stats",
      data: {
        map_keys: activeKeys,
        year: yearSelect.val(),
      }
    });
  }

  function addOrRemoveKey(activeKey) {
    if (activeKeys.includes(activeKey)){
      // Remove it from the list of active keys
      activeKeys = activeKeys.filter(function(value, index, arr){
        return value != activeKey;
      });
    } else {
      // Add it to the list of active keys
      activeKeys.push(activeKey)
    }
  }

  function clicked(d) {
    if (!d) return false; // Clicking on the background
    addOrRemoveKey(d.properties.KEY);
    regionSelect.val(activeKeys);
    regionSelect.trigger('change');
  }

  function reset() {
    d3.selectAll("path").classed("active", false);
    g.transition()
        .duration(750)
        .style("stroke-width", "1.5px")
        .attr("transform", "");
    boilerPlate.show();
    regionalReport.hide();
  }

  function redraw(){
    // Extract the width and height that was computed by CSS.
    let width = chartDiv.clientWidth;

    // Use the extracted size to set the size of an SVG element.
    svg
      .attr("width", width)
      .attr("height", width);
  }

  // Draw for the first time to initialize.
  // Timeout because without it, it thinks it is super
  // wide and thus super tall.
  setTimeout(function(){ 
    redraw();
    updateForms();
  }, 500);

  // Redraw based on the new size whenever the browser window is resized.
  window.addEventListener("resize", redraw);

});
