// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// https://stackoverflow.com/questions/55895494/is-not-defined-when-installing-jquery-in-rails-via-webpack
window.jQuery = $;
window.$ = $;

// this has to come after jQuery becase we need
// to be able to call .highcharts()
window.Highcharts = require("highcharts/highcharts.js")
window.itemSeries = require("highcharts/modules/item-series")
window.itemSeries(window.Highcharts)
// require("highcharts/css/highcharts.css")

import '../map'
import 'clipboard/dist/clipboard.min.js'
import '../social_share_button'
import '../old_site.css'
import '../overrides.scss'
import 'glider-js/docs/assets/css/glider.css'
import 'glider-js/docs/assets/js/glider.min.js'
import '../slider'
import '../responsive_nav'
import '../google_analytics'
import '../embedded.css'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
