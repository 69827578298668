let $jq = require('jquery')

document.addEventListener("turbolinks:load", function (e) {
  $jq(".navbar-small").hide();
  var navHolder = $jq(".navbar-small")
  var menuIcon = document.querySelector(".menu");

  menuIcon.addEventListener('click', function (event) {
    if ($jq(navHolder).is(":visible")) {
      $jq(navHolder).slideUp(300);
    } else {
      $jq(navHolder).slideDown(300);
    }
  })
})